import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import React from 'react'
import { Helmet } from 'react-helmet'
import SEO from '../components/seo'

import Comment from '../components/comment'
import Layout from '../components/layout'
import ObjectSelector from '../components/objectSelector'

import i18n from '../i18n/i18n'
import { getTitle } from '../util/title-helper'

import './styles/activity.css'

const Activity = ({ data: { activity, allActivities }, pageContext }) => {
  const { data } = activity

  const lang = pageContext.lang.split('-')[0]

  const forObjectSelector = allActivities.edges.map(edge => ({
    uid: edge.node.uid,
    data: {
      name: edge.node.data.name,
      pictures: [
        { picture: edge.node.data.subactivities[0].subactivitypicture },
      ],
    },
  }))

  return (
    <Layout page="activity">
      <Helmet>
        <title>{getTitle(lang, data.name.text)}</title>
      </Helmet>
      <SEO
        title={getTitle(lang, data.name.text)}
        lang={lang}
        description={data.description.text}
      />
      <ObjectSelector
        path={`/${lang}/${i18n[lang].activity.slug}`}
        objects={forObjectSelector}
        anchor="activity"
      />
      <div className="activity" id="activity">
        <h1 className="activity__name">{data.name.text}</h1>
        <div
          className="activity__description"
          dangerouslySetInnerHTML={{ __html: data.description.html }}
        />
        <div className="activity__comments">
          {data.comments.map(
            (comment, i) =>
              comment.comment.text && (
                <Comment
                  key={`comment-${i}`}
                  comment={comment.comment.text}
                  name={comment.commentname.text}
                />
              )
          )}
        </div>
        <div className="activity__sub-activities">
          {data.subactivities.map((subActivity, i) => (
            <div className="activity__sub-activity" key={`subactivity-${i}`}>
              <h2>{subActivity.subactivitytitle.text}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: subActivity.subactivitydescription.html,
                }}
              />
              {subActivity.subactivitypicture.localFile && (
                <Image
                  fluid={
                    subActivity.subactivitypicture.localFile.childImageSharp
                      .fluid
                  }
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Activity

export const pageQuery = graphql`
  query ActivityBySlug($uid: String!, $lang: String!) {
    activity: prismicActivity(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      data {
        name {
          text
        }
        description {
          html
          text
        }
        comments {
          comment {
            text
          }
          commentname {
            text
          }
        }
        subactivities {
          subactivitytitle {
            text
          }
          subactivitydescription {
            html
          }
          subactivitypicture {
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, srcSetBreakpoints: [800]) {
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
              }
            }
          }
        }
      }
    }
    allActivities: allPrismicActivity(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      edges {
        node {
          uid
          data {
            name {
              text
            }
            subactivities {
              subactivitypicture {
                url
                localFile {
                  childImageSharp {
                    thumbnail: fluid(
                      maxWidth: 400
                      maxHeight: 400
                      srcSetBreakpoints: [400]
                      cropFocus: CENTER
                    ) {
                      src
                      aspectRatio
                      srcSet
                      sizes
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
